.layout{
    padding:4%;
}
.monitor-header {
    /*position: fixed;*/
    height: 20%;
    width: 100%;
    text-align: center;
    color: rgb(124, 125, 126);
}
.location-div{
    padding:2%;
}
.location-img {
    width:5%;
}

.geer-img {
    width:60%;
    margin-left: 3%;
}
@media screen and (max-width: 500px){
    .init-div {
        font-size: 12px;
        overflow: hidden;
        padding-left: 5%;
        padding-right: 5%;
    }
    .chinese-label {
        font-size: 22px;
        margin-left: 5%;
    }
    .english-label {
        font-size: 12px;
        margin-left: 3%;
    }
    .content_left_text{
        font-size: 10px;
    }
    .content_middle{
        width:40%;
        font-size: 14px;
    }
    .content_middle_1{
        font-size: 12px;
    }
    .test_value{
        font-size: 18px;
    }
    .test_unit{
        font-size: 10px;
    }
    .footer_text{
        font-size: 11px;
    }
    .monitor-outdoor {
        padding: 3% 4% 3% 4%;
        border-radius: 10px;
    }
    .content_left {
        width: 18%;
        padding: 5% 5% 5% 2%;
        text-align: center;
    }
    .indoor-img {
        width:68%;
    }
    .text_unit{
        font-size: 11px;
    }

}
@media screen and (min-width: 500px) and (max-width: 800px){
    .init-div {
        font-size: 14px;
        overflow: hidden;
        padding-left: 5%;
        padding-right: 5%;
    }
    .chinese-label {
        font-size: 26px;
        margin-left: 5%;
    }
    .english-label {
        font-size: 16px;
        margin-left: 3%;
    }
    .content_left_text{
        font-size: 14px;
    }
    .content_middle{
        width:50%;
        font-size: 18px;
    }
    .content_middle_1{
        font-size: 16px;
    }
    .test_value{
        font-size: 22px;
    }
    .test_unit{
        font-size: 15px;
    }
    .footer_text{
        font-size: 15px;
    }
    .monitor-outdoor {
        padding: 3% 4% 3% 4%;
        border-radius: 10px;
    }
    .content_left {
        width: 10%;
        padding: 4% 8% 4% 8%;
        text-align: center;
    }
    .indoor-img {
        width:90%;
    }
    .text_unit{
        font-size: 18px;
    }

}
@media screen and (min-width: 800px) and (max-width: 1500px){
    .init-div {
        font-size: 30px;
        overflow: hidden;
        margin: 0.1%;
    }
    .chinese-label {
        font-size: 70px;
        margin-left: 5%;
    }
    .english-label {
        font-size: 40px;
        margin-left: 3%;
    }
    .content_left_text{
        font-size: 30px;
    }
    .content_middle{
        width:40%;
        font-size: 50px;
    }
    .content_middle_1{
        font-size: 40px;
    }
    .test_value{
        font-size: 70px;
    }
    .test_unit{
        font-size: 30px;
    }
    .footer_text{
        font-size: 40px;
    }
    .monitor-outdoor {
        padding: 3% 4% 3% 4%;
        border-radius: 10px;
    }
    .content_left {
        width: 18%;
        padding: 5% 5% 5% 2%;
        text-align: center;
    }
    .indoor-img {
        width:68%;
    }
    .text_unit{
        font-size: 42px;
    }
}
@media screen and (min-width: 1500px) and (max-width: 2000px){
    .init-div {
        font-size: 37px;
        overflow: hidden;
        margin: 0.1%;
    }
    .chinese-label {
        font-size: 77px;
        margin-left: 5%;
    }
    .english-label {
        font-size: 47px;
        margin-left: 3%;
    }
    .content_left_text{
        font-size: 37px;
    }
    .content_middle{
        width:40%;
        font-size: 57px;
    }
    .content_middle_1{
        font-size: 47px;
    }
    .test_value{
        font-size: 77px;
    }
    .test_unit{
        font-size: 37px;
    }
    .footer_text{
        font-size: 47px;
    }
    .monitor-outdoor {
        padding: 2% 4% 2% 4%;
        border-radius: 10px;
    }
    .content_left {
        width: 18%;
        padding: 5% 5% 5% 2%;
        text-align: center;
    }
    .indoor-img {
        width:68%;
    }
    .text_unit{
        font-size: 50px;
    }
}
/*rotate image*/
.wind-img {
    width: 8%;
    -webkit-animation:rotateImg 1s linear infinite;
    vertical-align: middle;
}

@keyframes rotateImg {
    0% {-webkit-transform : rotate(0deg);transform : rotate(0deg);}
    100% {-webkit-transform : rotate(360deg);transform : rotate(360deg);}
}

@-webkit-keyframes rotateImg {
    0%{-webkit-transform : rotate(0deg);}
    100%{-webkit-transform : rotate(360deg);}
}

.font-bold {
    font-weight: bold;
}

.monitor-indoor {
    padding-left:4%;
    padding-right:4%;
    border-radius: 10px;
}

.monitor-indoor-outdoor-header {
    background-color: rgb(29, 81, 141);
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.content_right{
    width: 30%;
}
.content_right1{
    padding-right: 10%;
}

.footer_left{
    float:left;
    width:50%;
}
.footer_right{
    float: right;
    width: 42%;
}

.footer_right_img_div{
    float:left;
    width:42%;
    padding:0 4% 0 4%;
}
.seperate-div {
    height: 1px;
    background-color: white;
}

.div-20 {
    height: 20px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

.monitor-indoor-body-item {
    background-color: rgb(72, 165, 222);
    color: white;
    display: flex;
    align-items: center;
}

.outdoor {
    background-color: rgb(230, 156 ,55);
}

.half-height {
    height: 50%;
}


.contact-div {
    overflow: hidden;
    padding-left: 4%;
    padding-right: 4%;
}
.gmair-image {
    width:50%;
}
.log-image {
    width: 65%;
    margin-left: 0px;
}
.qrcode-img {
    width:100%;
}
.border-bottom-raduis {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.aqi-img {
    width: 100%;
}
.pm2_5_excellent {
    background-color: #00CC66;
}

.pm2_5_moderate {
    background-color: #CC9900;
}

.pm2_5_sensative {
    background-color: #FF9966;
}

.pm2_5_unhealthy {
    background-color: #FF0066
}

.pm2_5_very_unhealthy {
    background-color: #990099;
}

.pm2_5_hazardous {
    background-color: #996666;
}

